<template>
  <div class="iss-main-grid">
    <!--     分页 -->
<!--    <div class="iss-search-wrap">-->
<!--      <search-form :items="items" @fnSearch="handleFnSearch" />-->
<!--    </div>-->
<!--    :search="search"  allow-selection  -->
    <div class="iss-grid-wrap mt-16">
      <grid
          ref="gridRef"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :btn-operation="btnOperation"
          :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #province="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>

        <template #isRelease="{ record }">
          <a-switch
              v-model:checked="record.isRelease"
              checked-children="是"
              un-checked-children="否"
              @click="handleClickSwitch(record)"
          />
        </template>

        <template #forcedUpdating="{ record }">
          <a-switch
              v-model:checked="record.forcedUpdating"
              checked-children="是"
              un-checked-children="否"
              @click="handleClickForcedUpdating(record)"
          />
        </template>
        <template #deviceType="{ record }">
          <div v-if="record.deviceType === 0">安卓</div>
          <div v-else-if="record.deviceType === 1">ios</div>
          <div v-else>鸿蒙</div>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>

      </grid>
    </div>
  </div>
  <edit-version
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
import { Modal, message, Switch } from 'ant-design-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {useRouter} from "vue-router";
import editVersion from "@/views/versionManagement/components/editVersion";
// import SearchForm from '@/components/searchForm';
import ordersApi from "@/api/orders.js";

export default {
  components: {
    // ARangePicker: DatePicker.RangePicker,
    // ASpace: Space,
    Operation,
    Grid,

    // SearchForm,
    ASwitch: Switch,
    editVersion,
  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      // search: {},
      TagList: [],
      visible: false,
      activeItem: {},

    });

    const clickOptions = ({ key }, record) => {
      console.log('更多操作', key, record);
      switch (key) {
        case 'order':
          router.push(`/user/${record.userId}?activeKey=3`)
          break;
        case 'invitation':
          router.push(`/user/${record.userId}?activeKey=2`)
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };



    return {

      gridRef,
      ...toRefs(state),
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        //  需要补上日期时间筛选
        // {
        //   key: 'date',
        //   timeKey: ['startCreateTime', 'endCreateTime'],
        //   label: '日期范围',
        //   type: 'rangePicker',
        // },
        // {
        //   key: 'content',
        //   label: '搜索问题内容关键字',
        //   type: 'input',
        // },

      ],
      columns: [
        { dataIndex: 'appName', title: '应用名称', width: 100, ellipsis: true,
          slots: { customRender: 'appName' },
        },
        { dataIndex: 'desc', title: '版本描述', width: 120, ellipsis: true,
          slots: { customRender: 'desc' },
        },
        { dataIndex: 'downloadUrl', title: 'ota链接', width: 200,  },
        { dataIndex: 'fileSize', title: '文件大小', width: 100, ellipsis: true,
          slots: { customRender: 'fileSize' },
        },
        { dataIndex: 'version', title: '版本名称', width: 120, ellipsis: true,
          slots: { customRender: 'version' },
        },
        { dataIndex: 'versionCode', title: '应用版本号', width: 200, ellipsis: true,
          slots: { customRender: 'versionCode' },
        },
        { dataIndex: 'channelName', title: '应用渠道', width: 200, ellipsis: true,
          slots: { customRender: 'channelName' },
        },
        { dataIndex: 'isRelease', title: '是否发布', width: 200, ellipsis: true,
          slots: { customRender: 'isRelease' },
        },
        { dataIndex: 'forcedUpdating', title: '是否强制更新', width: 200, ellipsis: true,
          slots: { customRender: 'forcedUpdating' },
        },
        { dataIndex: 'deviceType', title: '应用类型', width: 200, ellipsis: true,
          slots: { customRender: 'deviceType' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          label: '',
          icon: 'PlusOutlined',
          permission: 'goldMall:add',
          fnClick: () => {
            state.visible = true;

          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '修改',
          icon: 'EditTwoTone',
          permission: 'goldMall:update',
          fnClick: record => {
            state.visible = true;
            console.log('record', record)
            Object.assign(state.activeItem, record);
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: 'DeleteTwoTone',
          permission: 'goldMall:delete',
          fnClick: ({id}) => {
            ordersApi.delete(`configuration:delete`, { ids: [id] }).then(()=>{
              state.visible = false;
              message.success('操作成功');
              gridRef.value.refreshGrid();
            })
          },
        },
      ],
      url: ordersApi.versionPage,
      gridHeight: document.body.clientHeight - 260,
      clickOptions,
      // handleFnSearch: value => {
      //   const temp = Object.assign({}, value);
      //   // temp.tag = temp.tag?.join(',');
      //   state.search = temp;
      // },
      handleName: (record) => {
        return router.push(`/user/${record.userId}`)
      },
      handleView: (value) => {
        state.visible = value;
        gridRef.value.refreshGrid();
      },

      handleGotoView: ($event, record)=> {
        ordersApi.feedbackDetail('', record.id).then(res => {
          if (res) {
            state.activeItem = res
            state.visible = true
          }
        });
      },

      handleClickSwitch(record) {
        let obj = {
          ...record,
        }
        ordersApi.updateVersion('', {
          ...obj,
        }).then((res) => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      },

      handleClickForcedUpdating(record) {
        ordersApi.updateVersion('', {
          ...record,
        }).then((res) => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      }

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.contentCla {
  width: 200px;
  margin-left: 8px;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
}


</style>